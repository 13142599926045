bcl.c.mybPreferences = {
  personalElements: {
    form: ".js-preference-form",
    currency: ".js-input-currency",
    language: ".js-input-language",
    preferences: ".js-search-items",
    preferenceItem: ".js-search-item",
    countryFlag: ".js-change-flag",
    commercialCommunications: ".js-commercialCommunications",
  },

  modals: {
    activeClass: "tingle-modal--visible",
    successModal: ".js-success-modal",
    errorModal: ".js-error-modal",
  },

  props: {
    dynamicClass: ".c-myb-preferences.dynamicLoading",
    component: "c-myb-preferences",
    updateButton: ".js-save-preferences",
    info: {
      language: "string",
      currencyUser: "string",
      preferences: "array",
    },
    langList: null,
    inputs: {},
    preferencesData: [],
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.mybPreferences.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    const $component = baseFind.getElementsByClassName(bcl.c.mybPreferences.props.component);

    if (!$component || !$component[0]) {
      return;
    }

    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-mybPreferences.css", bcl.c.mybPreferences.props.dynamicClass);

    bcl.c.mybPreferences.props.endpointPostData = $component[0].dataset.urlPostData;
    bcl.c.mybPreferences.props.endpointPost = $component[0].dataset.urlPost;
    bcl.c.mybPreferences.setInputs($component[0]);

    setTimeout(() => {
      bcl.c.mybPreferences.addListeners($component[0]);
    }, 500);
  },

  addListeners: function (element) {
    const personalForm = element.querySelector(bcl.c.mybPreferences.personalElements.form);

    if (personalForm) {
      personalForm.addEventListener("submit", bcl.c.mybPreferences.submitPersonalForm);
    }

    const inputLanguage = element.querySelector(bcl.c.mybPreferences.personalElements.language);
    if (inputLanguage) {
      inputLanguage.addEventListener("click", function () {
        inputLanguage.classList.toggle("mod--open");
      });

      inputLanguage.addEventListener("change", function () {
        const langValue = inputLanguage.value;
        let countryCode = "";
        if (langValue.length > 2 && langValue != "ar" && langValue != "cs") {
          countryCode = langValue.substring(langValue.length - 2);
        } else if (langValue.length === 2 && langValue != "ar" && langValue != "cs") {
          countryCode = langValue;
        } else if (langValue == "cs") {
          countryCode = "cz";
        } else if (langValue == "ar") {
          countryCode = "sa";
        }
        const flagContainer = element.querySelector(bcl.c.mybPreferences.personalElements.countryFlag);
        flagContainer.innerHTML = "";
        flagContainer.innerHTML = `<span class='iti__flag iti__${countryCode.toLowerCase()}'></span>`;
      });
    }

    const inputCurrency = element.querySelector(bcl.c.mybPreferences.personalElements.currency);
    if (inputCurrency) {
      inputCurrency.addEventListener("click", function () {
        inputCurrency.classList.toggle("mod--open");
      });
    }

    const items = element.querySelectorAll(bcl.c.mybPreferences.personalElements.preferenceItem);
    items.forEach((item) => {
      item.addEventListener("click", () => {
        const key = item.dataset.value;
        const isActive = item.classList.toggle("mod--active");

        bcl.c.mybPreferences.props.preferencesData[key] = isActive;
      });
    });
  },

  submitPersonalForm: function (event) {
    event.preventDefault();
    const form = event.target;
    const invalidInput = form.querySelector(bcl.c.mybPreferences.props.invalidInput);
    if (!invalidInput) {
      bcl.c.mybPreferences.setValuekey("language", bcl.c.mybPreferences.props.inputs.language?.value);

      bcl.c.mybPreferences.setValuekey("currencyUser", bcl.c.mybPreferences.props.inputs.currency?.value);

      bcl.c.mybPreferences.props.info.isPreferences = true;
      bcl.c.mybPreferences.setValuekey("preferences", bcl.c.mybPreferences.props.preferencesData);

      const commercialCommunications = form?.querySelector(bcl.c.mybPreferences.personalElements.commercialCommunications)?.checked;
      bcl.c.mybPreferences.setValuekey("commercialCommunications", commercialCommunications);

      if (!form.classList.contains("errorValidationForm")) {
        bcl.c.mybPreferences.updateApiData(form);
      } else {
        bcl.c.mybPreferences.errorSubmit(event, form);
      }
    }
  },

  setValuekey: function (key, value) {
    if (!key || !value) {
      return;
    }
    bcl.c.mybPreferences.props.info[key] = value;
  },

  updateApiData: function (form) {
    bcl.ajax.postRequest({
      data: bcl.c.mybPreferences.props.info,
      url: bcl.c.mybPreferences.props.endpointPost,
      callback: (e) => {
        bcl.c.mybPreferences.successSubmit(e, form);
      },
      errorCallback: (e) => {
        bcl.c.mybPreferences.errorSubmit(e, form);
      },
    });
  },

  getApiData: function (form) {
    bcl.c.mybLoyaltyUserInfo.requestUserProfileData((e) => {
      bcl.c.mybPreferences.setApiData(e.responseText, form);
    }, (e) => {
      bcl.c.mybPreferences.errorSubmit(e, form);
    });
  },

  setApiData: function (responseText, form) {
    const data = JSON.parse(responseText);
    bcl.c.mybPreferences.props.data = data;
    if (data.currencyUser) {
      bcl.c.mybPreferences.props.inputs.currency.value = data.currencyUser;
    }
    if (data.language) {
      bcl.c.mybPreferences.props.inputs.language.value = data.language;
      const langValue = bcl.c.mybPreferences.props.inputs.language.value;
      let countryCode = "";
      if (langValue.length > 2 && langValue != "ar" && langValue != "cs") {
        countryCode = langValue.substring(langValue.length - 2);
      } else if (langValue.length === 2 && langValue != "ar" && langValue != "cs") {
        countryCode = langValue;
      } else if (langValue == "cs") {
        countryCode = "cz";
      } else if (langValue == "ar") {
        countryCode = "sa";
      }
      const flagContainer = form.querySelector(bcl.c.mybPreferences.personalElements.countryFlag);
      flagContainer.innerHTML = "";
      flagContainer.innerHTML = `<span class='iti__flag iti__${countryCode.toLowerCase()}'></span>`;
    }

    if (data.preferences) {
      bcl.c.mybPreferences.props.preferencesData = data.preferences;
      const preferencesData = data.preferences;
      const preferencesItems = form.querySelectorAll(bcl.c.mybPreferences.personalElements.preferenceItem);

      for (const key in preferencesData) {
        const preferenceElement = [...preferencesItems].find((el) => el.dataset.value === key);

        if (preferenceElement) {
          if (preferencesData[key]) {
            preferenceElement.classList.add("mod--active");
          } else {
            preferenceElement.classList.remove("mod--active");
          }
        }
      }
    }
    if (data?.commercialCommunications != "undefined" || data?.commercialCommunications != null) {
      const checkbox = form.querySelector(bcl.c.mybPreferences.personalElements.commercialCommunications);
      if (checkbox) {
        checkbox.checked = data.commercialCommunications;
      }
    }
  },

  setInputs: function (form) {
    if (form) {
      bcl.c.mybPreferences.props.inputs.form = form;
    }

    const language = form.querySelector(bcl.c.mybPreferences.personalElements.language);
    if (language) {
      bcl.c.mybPreferences.props.inputs.language = language;
    }

    const currency = form.querySelector(bcl.c.mybPreferences.personalElements.currency);

    if (currency) {
      bcl.c.mybPreferences.props.inputs.currency = currency;
    }

    const preferences = form.querySelector(bcl.c.mybPreferences.personalElements.preferences);
    if (preferences) {
      bcl.c.mybPreferences.props.inputs.preferences = preferences;
    }

    bcl.c.mybPreferences.getApiData(form);
  },

  successSubmit: function (e, form) {
    bcl.c.mybPreferences.props.info.isPreferences = false;
    const successModal = form.querySelector(bcl.c.mybPreferences.modals.successModal);
    const closeModal = successModal.querySelector(".js-close-modal");
    closeModal.addEventListener("click", () => {
      successModal.classList.remove(bcl.c.mybPreferences.modals.activeClass);
    });
    successModal.classList.add(bcl.c.mybPreferences.modals.activeClass);
  },

  errorSubmit: function (e, form) {
    bcl.c.mybPreferences.props.info.isPreferences = false;
    const errorModal = form.querySelector(bcl.c.mybPreferences.modals.errorModal);
    const closeModal = errorModal.querySelector(".js-close-modal");
    closeModal.addEventListener("click", () => {
      errorModal.classList.remove(bcl.c.mybPreferences.modals.activeClass);
    });
    errorModal.classList.add(bcl.c.mybPreferences.modals.activeClass);
  },
};
bcl.c.mybPreferences.preInit();
