bcl.c.mybLoyaltyUserInfo= {
  props: {
    userProfileUrl: null,
    userProfile: {
      callbackList: [],
      apiResponse: false,
      isApiCallInProgress: false,
      localStorageKey: "uis-data-cache"
    },
    userDataUrl: null,
    userData: {
      callbackList: [],
      apiResponse: false,
      isApiCallInProgress: false,
      localStorageKey: "giul-data-cache"
    },
  },
 
    preInit: function () {
      bcl.u.docReady(bcl.c.mybLoyaltyUserInfo.init);
    },

    init: function () {
      bcl.c.mybLoyaltyUserInfo.props.userProfileUrl = document.querySelectorAll("." + bcl.c.mybPreferences.props.component + ",." + bcl.c.mybEditProfileV2.props.component)[0]?.dataset?.urlPostData;
      bcl.c.mybLoyaltyUserInfo.props.userDataUrl = document.querySelectorAll(".cmp-l-hero,.cmp-l-info")[0]?.dataset?.urlUser;
    },

    userProfileApiCall: function(url, userDataObject) {
      if (url) {
        userDataObject.isApiCallInProgress=true;
        bcl.ajax.postRequest({
          url: url,
          callback: (e) => {
            bcl.c.mybLoyaltyUserInfo.storeDataCache(userDataObject, e);
            userDataObject.isApiCallInProgress=false;
            userDataObject.callbackList.forEach(callback => {
              if (callback.success) {
                callback.success(e);
              }
            });
            userDataObject.callbackList = [];
          },
          errorCallback: (e) => {
            userDataObject.callbackList.forEach(callback => {
              if (callback.error) {
                callback.error(e);
              }
            });
            userDataObject.callbackList = [];
          },
        });
      }
    },

    storeDataCache: function(userDataObject, data){
      userDataObject.apiResponse = data;
      const localStorageTimestamp = new Date().getTime();
      localStorage.setItem(userDataObject.localStorageKey, JSON.stringify({timestamp: localStorageTimestamp, responseText: data.responseText}));
    },

    getData: function (userDataObject) {
      if (!userDataObject.apiResponse) { 
        const dataCache = localStorage.getItem(userDataObject.localStorageKey);
        if (dataCache){
          const dataCacheObject = JSON.parse(dataCache);
          const dataCacheTime = (new Date().getTime()) - dataCacheObject.timestamp;
          if (dataCacheTime < 600000) {
            userDataObject.apiResponse = dataCacheObject;
          } else {
            localStorage.removeItem(userDataObject.localStorageKey);
          }
        }
      }
      return userDataObject.apiResponse;
    },

    requestData: function (url, userDataObject, successCallbackFunction, errorCallbackFunction) {
      const currentData = bcl.c.mybLoyaltyUserInfo.getData(userDataObject);
      if (currentData) {
        successCallbackFunction(currentData);
      } else {
        userDataObject.callbackList.push({
          success: successCallbackFunction,
          error: errorCallbackFunction
        });
        if (!userDataObject.isApiCallInProgress) {
          bcl.c.mybLoyaltyUserInfo.userProfileApiCall(url, userDataObject);
        }
      }
    },

    requestUserProfileData: function (successCallbackFunction, errorCallbackFunction) {
      if (!bcl.c.mybLoyaltyUserInfo.props.userProfileUrl){
        bcl.c.mybLoyaltyUserInfo.init();
      }
      bcl.c.mybLoyaltyUserInfo.requestData(bcl.c.mybLoyaltyUserInfo.props.userProfileUrl, bcl.c.mybLoyaltyUserInfo.props.userProfile, successCallbackFunction, errorCallbackFunction);
    },

    requestUserData: function (successCallbackFunction, errorCallbackFunction) {
      if (!bcl.c.mybLoyaltyUserInfo.props.userDataUrl){
        bcl.c.mybLoyaltyUserInfo.init();
      }
      bcl.c.mybLoyaltyUserInfo.requestData(bcl.c.mybLoyaltyUserInfo.props.userDataUrl, bcl.c.mybLoyaltyUserInfo.props.userData, successCallbackFunction, errorCallbackFunction);
    }
};

bcl.c.mybLoyaltyUserInfo.preInit();